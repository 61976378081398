import React, { Component } from "react";

class Header extends Component {
	render() {
		if (this.props.data) {
			var name = this.props.data.name;
			var networks = this.props.data.social.map(function (network) {
				return (
					<li key={network.name}>
						<a href={network.url} target="_blank" rel="noopener noreferrer">
							<i className={network.className} />
						</a>
					</li>
				);
			});
		}

		return (
			<header id="home">
				<nav id="nav-wrap">
					<a className="mobile-btn" href="#nav-wrap" title="Show navigation">
						Show navigation
					</a>
					<a className="mobile-btn" href="#home" title="Hide navigation">
						Hide navigation
					</a>

					<ul id="nav" className="nav">
						<li className="current">
							<a className="smoothscroll" href="#home">
								Home
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#about">
								About Me
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#portfolio">
								Projects
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#artwork">
								Art
							</a>
						</li>
						<li>
							<a href="assets/Roy Xu Resume.pdf">Resume</a>
						</li>
					</ul>
				</nav>

				<div className="row banner">
					<div className="banner-text">
						<h1 className="responsive-headline">{name}</h1>
						<h3>
							<b>Software Engineer | Part-time Artist | Weekend Hooper </b>
						</h3>
						<hr />
						<ul className="social">{networks}</ul>
					</div>
				</div>

				<p className="scrolldown">
					<a className="smoothscroll" href="#about">
						<i className="icon-down-circle" />
					</a>
				</p>
			</header>
		);
	}
}

export default Header;
